<template>
    <div class="row justify-content-center">
        <div class="col-md-8">
            <h3 class="pb-1">Informationen</h3>
            <p><b>EinweisungsApp</b></p>
            <p>Version {{ version }}</p>
            <p>&copy; {{ year }} Mario Lauterbach. All rights reserved.</p>
        </div>
        <div class="col-md-8 pt-4">
            <h4 class="pb-1">Fragen, Probleme, Feedback?</h4>

            <p><i class="bi bi-envelope-fill"></i> <a href="mailto:support@einweisungsapp.de">support@einweisungsapp.de</a><br>
            <i class="bi bi-whatsapp"></i> <a href="https://api.whatsapp.com/send?phone=4961513652641" target="_blank">Chat in WhatsApp starten</a><br>
            <i class="bi bi-telephone-fill"></i> <a href="tel:004961513652641">06151 365 26 41</a></p>
        </div>

    </div>
</template>

<script>
import '../../node_modules/bootstrap-icons/font/bootstrap-icons.css'
import store from "../store"

export default {
  data () {
    return {
        year: new Date().getFullYear(),
        version: store.getters.appVersion
    }
  },
  created () {
  },
  methods: {
        }
}
</script>
